@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

$terminalGreen: #2da815;
$terminalRed: #c8152d;

:root {
    --terminalGreen: $terminalGreen;
    --white: #ffffff;
}

html {
    overflow-y: auto;
    overflow-x: auto;
}

.full-page {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    min-height: 100vh;

    &.no-overflow {
        overflow-x: hidden;
    }
}

@media (max-width: 1000px) {
    .columns.is-mobile .form-email {
        flex: unset !important;
    }

    .button.is-medium,
    .is-medium.input {
        font-size: 1rem;

        &.big {
            font-size: 1rem;
        }
    }
}

body {
    color: #ddd;
    font-family: 'Source Sans 3', sans-serif;

    margin: auto;
    font-size: 130%;

    min-height: 100vh;
}

#root {
    min-height: 100vh;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.invisible {
    visibility: hidden;
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(-100%);
        height: 0;
    }

    100% {
        opacity: 1;
        transform: translateY(0);
        height: 1.5em;
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-200%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes redGreenFade {
    0% {
        background-color: $terminalRed;
    }

    100% {
        background-color: $terminalGreen;
    }
}

.slideDown {
    animation: slideDown 1s;
}

.transparent-footer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 1;
    padding-bottom: 1rem;
    padding-top: 1rem;

    div {
        align-self: flex-end;

        a h2 {
            color: #fffa;
            font-weight: 100;
            text-align: center;
            font-size: 90%;
            letter-spacing: .08rem;
            text-transform: none;

            &:hover {
                color: #fff;
            }

            text-shadow: #a 0px 0px 4px;
        }
    }
}

.text-center {
    text-align: center;
}

.expand {
    flex-grow: 1;
}

.lightLink {
    color: darken(#00d2ff, 10%);

    &:hover {
        color: darken(#00d2ff, 5%);
    }
}

.italic {
    font-style: italic;
}

.bold {
    font-weight: 800;
}

.light-h1 {
    font-family: "Source Sans 3", sans-serif;
    text-transform: unset;
    font-weight: 800;
    font-size: 2.5rem;
    color: #ffe;
    text-shadow: #ccc 0px 1px 1px;
    word-wrap: break-word;
    overflow-wrap: break-word;
}