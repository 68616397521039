@media (min-width: 1000px) {
    .classicPadded {
        padding: 0rem 5rem;
    }
}

@media (max-width: 1000px) {
    .classicPadded {
        padding: 0rem 3vw;
    }
}

body {
    background-color: #111;
}

.classicPadded {
    max-width: 1300px;

    background-color: #111;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    margin: auto;
}