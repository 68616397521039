$terminalGreen: #2da815;
$terminalRed: #c8152d;

form {
    margin-top: 1rem;

    h2 {
        text-align: center;
        margin-bottom: 0.3rem;
        font-weight: 600;
        text-shadow: 0px 0px 4px #fff8;
    }

    input {
        background-color: #fffb !important;
    }

    input:autofill {
        background-color: #fffb !important;
    }

    label {
        display: block !important;
        text-align: center;

        input {
            margin-right: 0.8rem;
            width: 1rem;
            height: 1rem;
        }

        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */

        color: #eee;

        &:hover {
            color: #cdc !important;
        }

        a {
            color: #88f;

            &:hover {
                color: #55e;
            }
        }
    }
}

@mixin button-base($bgButton, $textColor) {
    font-weight: 600;
    background-color: $bgButton;
    border-color: $bgButton;

    &:not(:disabled) {
        color: $textColor !important;
    }

    transition: all 0.5s ease;

    /*box-shadow: 3px 3px 10px lighten($bgButton, 4%);
    */
    &:hover {
        background-color: lighten($bgButton, 3%);
        border-color: lighten($bgButton, 3%);

        &:not(:disabled) {
            color: darken($textColor, 5%);
        }
    }
}

a.button {
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

button,
a.button {
    &.is-off {
        z-index: 50;
        $bgButton: #333f33;
        $textColor: $terminalGreen;
        @include button-base($bgButton, $textColor);
    }

    &.is-active,
    &.is-off:hover {
        $bgButton: $terminalGreen;
        $textColor: #fff;

        @include button-base($bgButton, $textColor);
    }

    &.is-red {
        z-index: 50;
        $bgButton: #3f3333;
        $textColor: $terminalRed;
        @include button-base($bgButton, $textColor);

        &.is-active,
        &:hover {
            z-index: 50;
            $bgButton: $terminalRed;
            $textColor: #3f3333;

            @include button-base($bgButton, $textColor);
        }
    }
}

.cartel {
    padding: 1rem 1.5rem 1rem 2rem;
    background-color: #444;
    box-shadow: inset 0em 0em 1rem #000;

    border-radius: 2rem;

    div {
        scroll-snap-type: y mandatory;
        overflow-y: auto;
        max-height: 100%;
        scrollbar-width: thin;
        padding-right: 0.5rem;
        padding-bottom: 0.8em;

        &::-webkit-scrollbar {
            width: 9px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(155, 155, 155, 0.5);
            border-radius: 20px;
            border: transparent;
        }

        * {
            scroll-snap-align: start;
        }

        p {
            font-size: 80%;
            font-weight: 200;
        }

        & strong {
            color: lightgray;
        }

        h3 {
            font-weight: 800;
            text-align: center;
            margin-bottom: 0.5rem;
        }

        & a {
            color: lightblue;
            font-weight: 400;
            text-decoration: underline;
        }

    }
}

.cartel-legal {
    h1 {
        line-height: unset;
        color: unset;
        text-shadow: unset;
        text-transform: unset;
        font-weight: unset;
        font-size: unset;
        margin-top: 1rem;

    }

    p {
        text-align: justify;
        margin-top: 0.5rem;
    }

    ul {
        list-style: circle;
        padding-left: 1rem;
    }
}

.login-tooltip {
    animation: slideUp 1s;
    font-weight: 100;
    margin-bottom: 0;
    margin-top: -0.5em;
    font-size: 0.9em;
    text-transform: none;


    &.is-ok {
        color: $terminalGreen;

        text-shadow: 2px 2px 2px rgba(darken($terminalGreen, 10%), 0.9);
    }

    &.is-nok {
        color: lighten($terminalRed, 15%);

        text-shadow: 2px 2px 2px rgba(darken($terminalRed, 10%), 0.9);
    }
}

.is-text-white {
    color: white !important;

    &.button.is-red.is-active {
        color: white !important
    }
}