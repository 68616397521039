$radiusCodeAppAngles: 5px;

.code-app-code-columns {
    min-width: 60%;
}

.code-app-field {
    input {
        border-radius: $radiusCodeAppAngles $radiusCodeAppAngles 0 0;
        border: 0;
    }

    .no-radius {
        border-radius: 0;
    }

    button.is-active {
        border-color: #2da815;
        border-radius: 0 0 $radiusCodeAppAngles $radiusCodeAppAngles;

        &.is-red {
            border-color: #a82d15;
        }

        &.is-pale {
            filter: saturate(60%)
        }



        &.is-top {
            border-radius: $radiusCodeAppAngles $radiusCodeAppAngles 0 0;
        }

        &.is-full {
            border-radius: 0;
        }

        &.is-left {
            border-radius: $radiusCodeAppAngles 0 0 $radiusCodeAppAngles;
        }

        &.is-right {
            border-radius: 0 $radiusCodeAppAngles $radiusCodeAppAngles 0;
        }

        &.size-fix {
            width: 12vw;
            max-width: 90px;
        }
    }

    &.inline-buttons {
        display: flex;
        flex-direction: row;

        .core-buttons {
            display: flex;
            gap: 0.2em;
            flex-direction: row;
        }

        margin: auto;
        align-items: center;
        justify-content: center;
    }


}

.code-app-body {
    .bottom-part {
        margin-top: 2rem;

        h2 {
            text-transform: none;
            font-weight: 600;
        }

        .text-encart {
            color: #222;
            background-color: #ccc;
            padding: .8rem 1.5rem;
            border-radius: .8rem;
            font-size: 1rem;
            text-align: justify;

            &.text-center {
                text-align: center;
            }

            b {
                color: #111;
            }
        }
    }
}