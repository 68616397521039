@keyframes fadeInMessages {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.message-app {
    margin: 0 auto;
    width: 75%;
    max-width: 750px;

    @media (max-width: 1000px) {
        width: 90%;
    }

    padding-bottom: 2rem;

    &.no-expand {
        width: auto;
        margin: 0;
    }

    .app-title {
        text-transform: none;
    }

    .message-in {
        background-color: #fdd;

        &.stolen {
            background-color: #dde;
            color: #030303;

            border-radius: 1.25rem;
            margin-left: 0.5rem;
        }

        color: #030303;
        border-radius: 1.25rem 1.25rem 1.25rem 0;
        padding: 1.5rem;

        text-align: justify;


        &:not(:first-child) {
            margin-top: 1rem;
        }

        p {
            line-height: 110%;

            &:not(:last-child) {
                margin-bottom: 0.4rem;
            }
        }

        &.message-short {
            padding: 0.7rem 1.5rem;

            &.stolen {
                padding: 0.7rem 1rem;
            }

            width: fit-content;
            max-width: 75%;
            line-height: 109%;
        }
    }

    .message-out {
        background-color: #dfd;

        color: #030303;
        border-radius: 1.25rem 1.25rem 0rem 1.25rem;
        padding: 1.5rem;

        &.message-short {
            padding: 0.5rem 1.5rem;
        }

        text-align: justify;


    }

    button.message-out.is-active {
        color: #030303 !important;
        font-size: 90%;
    }

    input {
        background-color: #f0fff0;
        border: 2px solid #393;
        font-style: italic;
        font-weight: 700;
        font-size: 120%;
        width: 9rem;
    }

    .loading-message {
        padding: 0.7rem 1rem;
        text-align: left;
    }

    .message-on-right {
        display: flex;
        align-items: flex-end;

        &:not(:first-child) {
            margin-top: 1rem;
        }

        div {
            margin-left: auto;
        }
    }

    .italic {
        font-style: italic;
    }

    .bold {
        font-weight: 800;
    }

    &.appear,
    .appear {
        animation: fadeIn 1s;
        animation-fill-mode: forwards;
    }
}