.concept-container {
    gap: 3vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.concept-line {
    display: flex;
    gap: 1rem;
    max-width: 100vw;

    .image {
        flex-grow: 1;
        max-width: 222px;

        &::after {

            content: '';
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &:first-child {
            &::after {

                box-shadow: inset 5px 0px 15px 0 #000000;
                border-radius: 50px 5px 5px 50px;
            }

            img {
                border-radius: 50px 5px 5px 50px;
            }
        }

        &:last-child {

            &::after {
                box-shadow: inset -5px 0px 15px 0 #000000;
                border-radius: 5px 50px 50px 5px;
            }

            img {
                border-radius: 5px 50px 50px 5px;
            }
        }
    }

    div {
        align-self: center;
    }

    div.concept-body {
        flex-grow: 2;

        .concept-header {
            font-size: 1.5em;
        }

        font-size: 0.9em;
        line-height: 1;

        &:first-child {
            .concept-header {
                text-align: right;
            }

            text-align-last: right;
        }

        text-align: justify;

        &:last-child {
            padding-right: 2vw;
        }

        &:first-child {
            padding-left: 2vw;
        }
    }
}