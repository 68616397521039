.audio-player-bar {

    border: 2px solid #f76565;
    display: flex;
    background-color: black;
    padding: 0 1rem;

    canvas {
        flex-grow: 1;
        max-width: 100%;
    }
}