$textColor: #222;

@import url('https://fonts.googleapis.com/css2?family=Love+Ya+Like+A+Sister&display=swap');


.text-document {
    font-family: 'CourierPrime',
        monospace;
    display: flex;
    flex-direction: column;
    color: #111;
    font-size: 13pt;
    text-align: justify;
    background: white;
    min-height: 100vh;
    min-width: 100%;
    font-size: 120%;
    padding: 2rem 2.5rem;

    .title {
        font-weight: 900;
        margin-bottom: 1em;
    }

    .blink {
        width: 3px;
        left: 8px;
        height: 1em;
        display: inline-block;
        position: relative;
        top: 1px;
        background-color: #111;
        animation: blinker 1s linear infinite;
    }

    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }


}

.floating-bottom {
    position: fixed;
    bottom: 0;
    display: block;
    left: 0;
    right: 0;

    & .button {
        font-family: 'CourierPrime',
            monospace;
        margin: auto;
        display: block;
        width: 33vw;
        max-width: 50vw;

        background-color: #00000011;
        box-shadow: 0em 0em 10px #00000088;
        margin-bottom: 3rem;
        border: 2px solid #222;
        font-weight: 600;
        color: #333;

        transition: all 0.5s ease;

        &:hover {
            background-color: #00000033;
            border-color: #000;

            &:not(:disabled) {
                color: #000;
            }
        }
    }
}