$sizes: (
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9
);

@mixin generate-grid-sizes() {
    @each $size in $sizes {
        &.grid-#{$size} {
            grid-template-columns: repeat($size, 1fr);

            .square:nth-child(#{$size}n+#{1}) {
                border-left: 2px solid #55f;
                box-shadow: inset 0.6vw 0vw 0.6vw #55f;
            }

            .square:nth-child(#{$size}n) {
                border-right: 2px solid #5f5;
                box-shadow: inset -0.6vw 0vw 0.6vw #5f5;
            }

            .square:first-child:last-child {
                border-right: 2px solid #5f5;
                box-shadow: inset -0.3vw 0vw 0.6vw #5f5, inset 0.3vw 0vw 0.6vw #55f;
            }
        }

    }
}

.toulouse-final {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: fit-content;
    margin: auto;

    .top-part {
        margin-top: 3.5vw;
        margin-bottom: 4vw;
    }


    .final-selector {
        width: 40vw;
    }

    .final-size-selector {
        display: flex;
        margin-bottom: 1rem;

        button {
            width: 4vw;
            flex-grow: 1;
            border-width: 0;
            padding: 0;

            &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }


        .input {
            flex-grow: 1;
            width: 4vw;
            border-width: 0;
            border-radius: 0;
            text-align: center;
            padding: 0;

            p {
                margin: auto;
            }
        }

    }

    .grid-container {
        flex-grow: 1;
    }


    .final-grid {
        display: grid;
        margin: 1em;

        @include generate-grid-sizes();

        .square {
            border: 2px solid #eee;
            width: 8vw;
            height: 8vw;
            box-shadow: inset 0vw 0vw 0.6vw #fff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .code-app-body {
        width: 100%;

        .code-app-code-columns {
            width: 100%;

            &>.column {
                padding: 0;
            }
        }
    }
}