$textColor: #222;

@import url('https://fonts.googleapis.com/css2?family=Love+Ya+Like+A+Sister&display=swap');

.a4unset.stolen-note {

    .censored {
        background-color: #000;
        display: inline;
    }

    font-family: 'CourierPrime',
    monospace;
    display: flex;
    flex-direction: column;
    color: #111;
    font-size: 13pt;
    text-align: justify;

    .main {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .signature {
        font-family: "Love Ya Like A Sister";
        font-weight: 1000;
        font-size: 30pt;
        margin-left: auto;
        margin-top: 1.5cm;
        margin-right: 0.5cm;
    }

    .warning-stamp {
        font-family: 'CourierPrime-Bold', 'monospace bold', monospace;
        width: fit-content;
        border: 3mm double #c00;
        border-radius: 1mm;
        text-align: center;
        padding: 0.5cm;
        color: #c00;
        opacity: 0.55;
        transform: rotate(-12deg);
        position: absolute;

        bottom: 15mm;
        right: 10mm;

        h1 {
            font-size: 20pt;
            font-weight: 1000;
            text-transform: uppercase;
        }
    }
}