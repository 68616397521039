$documentAppBackground: #f8f8f8;

.link-list-ul {

  padding-left: .75rem;
  padding-right: .75rem;

  a:not(:last-child) {
    margin-bottom: .5rem;
  }

  a {
    width: 100%;
    box-shadow: inset 0em 0em 1rem darken($documentAppBackground, 19%);

    box-sizing: border-box;
    border-radius: 8px;
    padding: 0.5rem;
    height: auto;

    width: 100%;
    color: #555;

    font-size: 1.25rem;

    &:hover {
      box-shadow: inset 0em 0em 1rem darken($documentAppBackground, 45%);
      color: #000;
    }
  }
}

h2.app-title {
  text-align: center;

  &.link-list {
    text-transform: capitalize;
  }

  font-weight: 800;
  font-size: 2rem;
  margin-bottom: .5rem;
  color: #efefef;
}