$bodyMargin: 0.3cm;
$textColor: #263f61;
$lightColor: #eedfc4;

.a4unset.mission-order {

    color: $textColor;

    &.page {
        display: flex;
        flex-direction: column;
    }


    .main {
        padding: 0.3cm $bodyMargin;
        font-family: 'GlacialIndifference', sans-serif;
        font-size: 12.5pt;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        p {
            text-align: justify;

            &:not(:last-child) {
                margin-bottom: 0.3cm;

                &.extraSpace {
                    margin-bottom: 0.6cm;
                }
            }

        }

        .expand {
            flex-grow: 1;
        }

        .strong {
            font-weight: 800;
        }
    }

    .header {
        background-color: blanchedalmond;
        border-top: 0.05cm solid black;
        border-bottom: 0.05cm solid black;

        font-family: 'Montserrat', sans-serif;
        font-size: 18.2pt;
        padding: 0.3cm 3*$bodyMargin;
        font-weight: 300;

        .strong {
            font-weight: 900;
            margin-right: 0.2cm;
            text-transform: uppercase;
        }

        margin-bottom: 0.75cm;
    }

    .end {
        display: flex;
        font-size: 15pt;
        align-items: center;

        padding-left: $bodyMargin;
        padding-right: $bodyMargin;
        margin-bottom: 0.3cm;

        .left {
            flex-grow: 1;

            .tagline {
                font-family: 'GlacialIndifference', sans-serif;
                font-style: italic;
                margin-bottom: 0.2cm;
            }
        }
    }

    .footer {
        padding-left: $bodyMargin;
        padding-right: $bodyMargin;
        padding-top: 0.2cm;
        padding-bottom: 0.2cm;
        font-family: 'Quando';
        font-size: 8pt;

        border-top: 0.05cm solid $textColor;

        background-color: unset;
        color: darken($lightColor, 55%);
        text-transform: uppercase;

        p {
            text-justify: auto;
            text-align: center;
            letter-spacing: 0.15pt;
        }
    }
}