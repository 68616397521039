$homeBackground: #202020ea;
$navbarBackground: #fedddd;
$terminalRed: #c8152d;

.navbar {
    display: flex;

    .navbar-brand {
        font-weight: 900;

        img {
            width: 50px;
            height: 50px;
            margin: auto 0;
            padding-right: 0;
        }

        a,
        p {
            padding-left: 0;

            &:visited {
                color: #4a4a4a;
            }

            &:hover {
                color: #000
            }
        }
    }

    .navbar-end {
        align-self: flex-end;
        margin-left: auto;

        .navbar-button {
            background-color: #fff2;
            color: #fff;
            border: 2px solid $terminalRed;


            &:hover {
                background-color: #fff1;
                border: 2px solid darken($terminalRed, 10%);
            }

            &:active {
                background-color: #fff0;
                border: 2px solid lighten($terminalRed, 10%);
            }

            &.is-rounded {
                padding-left: 1em;
                padding-right: 1em;
            }
        }
    }

    background-color: $navbarBackground;
    box-shadow: 0 -1px 8px #000;
}

.flex-content {
    background-color: $homeBackground;
    background-repeat: repeat;
    padding: 1.5rem 0.8rem 0.8rem 0.8rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .columns {
        margin-left: 0;
        margin-right: 0;
        display: flex;

        .column {
            display: flex;
        }
    }
}

.block-content {
    .columns {
        .column {
            display: block;

            h3 {
                font-weight: 800;
                font-size: 110%;
                margin-bottom: .5rem;
            }

            p.thin {
                font-weight: 300;
            }
        }
    }
}

.cartel-home {
    height: 30vh;
    padding: 1rem 2rem;
    background-color: darken($homeBackground, 4%);
    color: #ccc;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: content-box;
    flex-grow: 1;
}

.button-cartel-light {
    border-radius: 15px;
    background-color: #ffffff00;

    box-shadow: 0em 0em 0.5rem darken($homeBackground, 8%);
    border: 1px solid #ccc;
    font-size: 0.8em;
    color: #ccc;

    &:hover {
        border: 1px solid #555;
        box-shadow: 0em 0em 0.5rem darken($homeBackground, 18%);

        background-color: #ffffff33;
        color: #eee;
    }

    &:focus {

        box-shadow: 0em 0em 0.5rem darken($homeBackground, 8%) !important;
        border-color: #ccc;
        color: #ccc;
    }

    &:active {
        background-color: #000a;
        color: #fff;
    }
}

.button-cartel-admin {
    background-color: #ff222288;
    font-weight: bold;
    color: #fff;
    border-width: 2px;

    &:hover {
        background-color: #99222288;
    }
}

.centered-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.button-cartel {
    width: 100%;
    box-shadow: inset 0em 0em 1rem darken($homeBackground, 19%);

    box-sizing: border-box;
    padding: 1.2em;

    border: 0px solid #555;

    p {
        font-size: Min(4.5vw, 1.5em);
        font-weight: 900;
    }

    &:hover {
        border: 0px solid #555;
        box-shadow: inset 0em 0em 1rem darken($homeBackground, 38%);

        color: #fff;

        img {
            filter: grayscale(100%) brightness(0) invert(90) brightness(0.9) drop-shadow(3px 3px 4px green);
            max-height: 60%;
            margin-bottom: -0.9em;
        }
    }

    img {
        max-height: 50%;
        width: auto;
        filter: grayscale(100%) brightness(0) invert(90) brightness(0.7) drop-shadow(2px 2px 4px #f00c);
    }

    &.button-disabled {
        cursor: not-allowed;
        //box-shadow: inset 0em 0em 1rem darken($homeBackground, 5%);
        opacity: 0.8;

        p {
            color: #bbb;
            font-size: Min(4.5vw, 1.5em);
            font-weight: 900;
        }

        img {
            max-height: 50%;
            width: auto;
            filter: grayscale(100%) brightness(0) invert(90) brightness(0.5) drop-shadow(2px 2px 4px #f00c);
        }


        &:hover {
            color: #bbb;

            img {
                filter: grayscale(100%) brightness(0) invert(90) brightness(0.5) drop-shadow(3px 3px 4px #f00c);
                max-height: 60%;
                margin-bottom: -0.9em;
            }
        }
    }
}

@mixin button-base($bgButton, $textColor) {
    font-weight: 600;
    background-color: $bgButton;
    border-color: $bgButton;
    color: $textColor !important;
    transition: all 0.5s ease;

    /*box-shadow: 3px 3px 10px lighten($bgButton, 4%);
    */
    &:hover {
        background-color: lighten($bgButton, 5%);
        border-color: lighten($bgButton, 5%);
        color: darken($textColor, 5%);
    }
}

.is-right {
    float: right
}

.popup-tutorial {
    position: absolute;

    background-color: #eee;
    color: #111;
    border-radius: 10px;
    padding: 0.5rem 0.6rem;
    font-size: 1.2rem;

    p {
        text-align: justify;
        margin-bottom: 0.3rem;
    }

    z-index: 150;
}

.popup-tutorial-background {

    position: fixed;
    left: -25vw;
    right: 25vw;
    top: -25vh;
    bottom: 25vh;
    z-index: 100;
    height: 150vh;
    width: 150vw;
    overflow: hidden;
    background-color: rgba(#000, 0.3);
}

.popup-button-box {
    margin-top: 1rem;
    padding: 0 auto;

    &>p {
        font-size: 0.95rem;
        text-align: center;
    }

    .control {
        flex-grow: 1;
    }

    .button {
        margin-bottom: 0;
        @include button-base(#ddd, #222);

        &.is-active {
            @include button-base(#eee, #222);
        }

        &.is-inactive {
            @include button-base(#888, #333);
        }

        &.available.is-inactive {
            @include button-base(#bbb, #333);
            border: 2px solid #555;
        }

        .lock {
            padding: 0.2rem 0;
        }
    }

    p.control {
        .button {
            border: 2px solid #555;
            border-right: 1px solid #222;
            border-left: 1px solid #222;
        }
    }

    p.control:not(.yes-no):first-child {
        .button {
            border-left: none;
        }
    }

    p.control:not(.yes-no):last-child {
        .button {
            border-right: none;
        }
    }

    .yes-no {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        p.control {
            .button {
                border: 0px solid #555;
                border-radius: 0.5rem;

                &:hover {
                    text-transform: uppercase;

                    &.yes {
                        color: #040 !important;
                        background-color: #cdc;
                    }

                    &.no {
                        color: #400 !important;
                        background-color: #dcc;
                    }
                }
            }
        }

    }
}