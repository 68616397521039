$textColor: #222;

.a4unset.transcript {
    font-family: 'CourierPrime', monospace;
    color: $textColor !important;
    display: flex;
    flex-direction: column;

    .transcript-header {
        h1 {
            font-size: 20pt;
            text-transform: uppercase;
            word-spacing: -0.1cm;
            margin-bottom: -0.1cm;
        }

        h2 {
            font-size: 13pt;
            font-family: 'CourierPrime-Bold', 'monospace bold', monospace;
            text-transform: uppercase;

        }

        margin-bottom: 0.7cm;
        margin-top: 0.2cm;
    }

    .transcript-main {
        flex-grow: 1;

        p {
            font-size: 12pt;

            .dash {
                margin: 0cm 0.3cm;
            }

            &.strong {
                font-family: 'CourierPrime-Bold', 'monospace bold', monospace;
            }

            &.line {
                padding-left: 0.3cm;
            }
        }

        .transcriptElement {
            &:not(:last-child) {
                margin-bottom: 0.3cm;
            }
        }
    }

    .transcript-footer {
        border-top: 0.05cm solid $textColor;
        font-size: 10pt;
        margin-bottom: 0.3cm;

        p {
            padding-left: 0.3cm;
        }
    }
}