$textColor: #222;

@import url('https://fonts.googleapis.com/css2?family=Love+Ya+Like+A+Sister&display=swap');


.image-document {
    background: white;
    min-height: 100vh;
    min-width: 100vw;


    img {
        display: block;
        min-height: 100vh;
        min-width: 100vw;
        height: auto;
        width: auto;
        max-width: unset;
        max-height: unset;
    }

    &.is-horizontal {
        overflow-y: hidden;

        img {
            max-height: 100vh;
        }
    }


    &.is-vertical {
        overflow-x: hidden;

        img {
            max-width: 100vw;
        }
    }

    &.is-fit {
        overflow-y: hidden;
        background-color: #222;


        height: 100vh;
        position: relative;

        img {

            min-height: unset;
            min-width: unset;
            width: auto;
            max-width: 100vw;
            max-height: unset;

            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;

        }
    }

}

.floating-bottom {

    position: fixed;
    bottom: 0;
    display: block;
    left: 0;
    right: 0;

    & .button {
        font-family: 'CourierPrime',
            monospace;
        margin: auto;
        display: block;
        width: 33vw;
        max-width: 50vw;

        background-color: #ffffff99;
        box-shadow: 0em 0em 10px #00000088;
        margin-bottom: 3rem;
        border: 2px solid #222;
        font-weight: 600;
        color: #333;

        transition: all 0.5s ease;

        &:hover {
            background-color: #ffffff66;
            border-color: #000;

            &:not(:disabled) {
                color: #000;
            }
        }
    }
}