.a4unset {

    @font-face {
        font-family: 'Montserrat';
        src: url('../fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
    }

    @font-face {
        font-family: 'Montserrat-Italic';
        src: url('../fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf');
    }

    @font-face {
        font-family: 'GlacialIndifference';
        src: url('../fonts/glacial-indifference/GlacialIndifference-Regular.otf');
    }

    @font-face {
        font-family: 'GlacialIndifference-Italic';
        src: url('../fonts/glacial-indifference/GlacialIndifference-Italic.otf');
    }

    @font-face {
        font-family: 'GlacialIndifference-Bold';
        src: url('../fonts/glacial-indifference/GlacialIndifference-Bold.otf');
    }

    @font-face {
        font-family: 'Quando';
        src: url('../fonts/Quando/Quando-Regular.ttf');
    }

    @font-face {
        font-family: 'CourierPrime';
        src: url('../fonts/Courier_Prime/CourierPrime-Regular.ttf');
    }

    @font-face {
        font-family: 'CourierPrime-Bold';
        src: url('../fonts/Courier_Prime/CourierPrime-Bold.ttf');
    }

    h1 {
        all: unset;
    }


    font: unset;

}

@media (orientation: portrait) {
    body:has(.a4unset) {
        padding: 0rem 3rem;

        @media print {
            padding: 0;
        }

        width: fit-content;
        height: fit-content;
        min-width: 100%;
        min-height: 100%;
        background-size: cover;
    }
}